[
    {
      "id": 0,
      "constraints": {
        "top": [0, 5, 8, 9, 10],
        "right": [0, 3, 6, 9, 11],
        "bottom": [0, 2, 3, 4, 5],
        "left": [0, 4, 7, 10, 11]
      }
    }, {
      "id": 1,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [1, 2, 3, 5, 6, 8, 9]
      }
    }, {
      "id": 2,
      "constraints": {
        "top": [0, 5, 8, 9, 10],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [1, 2, 3, 5, 6, 8, 9]
      }
    }, {
      "id": 3,
      "constraints": {
        "top": [0, 5, 8, 9, 10],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [0, 4, 7, 10, 11]
      }
    }, {
      "id": 4,
      "constraints": {
        "top": [0, 5, 8, 9, 10],
        "right": [0, 3, 6, 9, 11],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [1, 2, 3, 5, 7, 8, 9]
      }
    }, {
      "id": 5,
      "constraints": {
        "top": [0, 5, 8, 9, 10],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [0, 2, 3, 4, 5],
        "left": [1, 2, 3, 5, 6, 8, 9]
      }
    }, {
      "id": 6,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [0, 4, 7, 10, 11]
      }
    }, {
      "id": 7,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [0, 3, 6, 9, 11],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [1, 2, 3, 5, 6, 8, 9]
      }
    }, {
      "id": 8,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [0, 2, 3, 4, 5],
        "left": [1, 2, 3, 5, 8, 9]
      }
    }, {
      "id": 9,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [1, 2, 4, 5, 7, 8, 10],
        "bottom": [0, 2, 3, 4, 5],
        "left": [0, 4, 7, 10, 11]
      }
    }, {
      "id": 10,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [0, 3, 6, 9, 11],
        "bottom": [0, 2, 3, 4, 5],
        "left": [1, 2, 3, 5, 6, 8, 9]
      }
    }, {
      "id": 11,
      "constraints": {
        "top": [1, 2, 3, 4, 6, 7, 11],
        "right": [0, 3, 6, 9, 11],
        "bottom": [1, 6, 7, 8, 9, 10, 11],
        "left": [0, 4, 7, 10, 11]
      }
    } 
  ]
